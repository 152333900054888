var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('div',{staticClass:"col-12 col-xs-12 col-lg-8"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.clearFilter}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-spacer'),_c('ExportTableJson',{staticStyle:{"padding-top":"0px !important","margin-bottom":"15px !important"},attrs:{"export-conditions":[],"export-data":_vm.contracts,"export-fields":_vm.headers,"export-source":'ets-due'}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.contracts,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"items":_vm.contracts,"items-per-page":-1,"headers":_vm.headers,"height":_vm.tableHeight,"search":_vm.searchTerm,"sort-by":['Contract.osd'],"dense":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","id":"etsDueTable"},scopedSlots:_vm._u([{key:"item.Contract.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.$can('update','Shipment'))?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.updateShipment(item.Contract.id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateShipment')))],1):_vm._e()],1)],1)]}},{key:"item.Contract.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap font-weight-bold"},[_vm._v(_vm._s(item.Contract.title))])]}},{key:"item.Contract.osd",fn:function(ref){
var item = ref.item;
return [_c('div',{class:'text-no-wrap font-weight-bold' + (_vm.overDue(item.Contract.osd) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.formatDate(item.Contract.osd)))])]}},{key:"item.Contract.ets",fn:function(ref){
var item = ref.item;
return [_c('div',{class:'text-no-wrap font-weight-bold' + (_vm.overDue(item.Contract.ets) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.formatDate(item.Contract.ets)))])]}},{key:"item.Contract.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Contract.status))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }