<template>
    <v-fade-transition>
        <div class="col-12 col-xs-12 col-lg-8">
            <v-sheet class="dense-inputs">
                <v-row no-gutters>
                    <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                        <v-text-field
                            :label="$t('message.filterResults')"
                            :value="searchTerm"
                            autocomplete="off"
                            class="mt-2 mb-0 force-text-left"
                            prepend-inner-icon="mdi-filter-outline"
                            @change="searchTerm = $event"
                        ></v-text-field>
                        <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                        <v-btn class="ml-3" @click="clearFilter"><v-icon>refresh</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <ExportTableJson
                            :export-conditions="[]"
                            :export-data="contracts"
                            :export-fields="headers"
                            :export-source="'ets-due'"
                            style="padding-top: 0px !important; margin-bottom: 15px !important;"
                        ></ExportTableJson>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading.contracts"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :items="contracts"
                :items-per-page="-1"
                :headers="headers"
                :height="tableHeight"
                :search="searchTerm"
                :sort-by="['Contract.osd']"
                class="mt-0 appic-table-light specification-table"
                dense
                disable-pagination
                fixed-header
                hide-default-footer
                id="etsDueTable"
            >
                <template v-slot:item.Contract.id="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateShipment(item.Contract.id)" v-if="$can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:item.Contract.title="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.Contract.title }}</div>
                </template>
                <template v-slot:item.Contract.osd="{item}">
                    <div :class="'text-no-wrap font-weight-bold' + (overDue(item.Contract.osd) ? ' red--text text--darken-1' : '')">{{ formatDate(item.Contract.osd) }}</div>
                </template>
                <template v-slot:item.Contract.ets="{item}">
                    <div :class="'text-no-wrap font-weight-bold' + (overDue(item.Contract.ets) ? ' red--text text--darken-1' : '')">{{ formatDate(item.Contract.ets) }}</div>
                </template>
                <template v-slot:item.Contract.status="{item}">
                    <div class="text-no-wrap">{{ item.Contract.status }}</div>
                </template>
            </v-data-table>
        </div>
    </v-fade-transition>
</template>

<script>
import {formatDate, formatDateShort, numberFormat} from "Helpers/helpers";
import {api} from "Api";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "EtsDueListing",
    components: { ExportTableJson },
    data() {
        return {
            contracts: [],
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            loading: {
                clear: false,
                contracts: false,
                filterResults: false
            },
            searchTerm: null,
            tableHeight: '500'
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Contract.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.contract'),
                    value: 'Contract.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 3,
                    text: this.$t('message.buyer'),
                    value: 'Contract.buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 4,
                    text: this.$t('message.supplier'),
                    value: 'Contract.supplier',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 5,
                    text: this.$t('message.container'),
                    value: 'Contract.container',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 6,
                    text: this.$t('message.osd'),
                    value: 'Contract.osd',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 7,
                    text: this.$t('message.ets'),
                    value: 'Contract.ets',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 8,
                    text: this.$t('message.status'),
                    value: 'Contract.status',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
            ]
        }
    },
    methods: {
        clearFilter() {
            this.searchTerm = null
            this.loadReport()
        },
        formatDate,
        formatDateShort,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (200);
        },
        loadReport() {
            return new Promise((resolve, reject) => {
                this.loading.contracts = true
                api
                    .get('/reports/ets-due')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.contracts = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.contracts = false
                    })
                    .catch((error) => {
                        this.$toast.error( error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.contracts = false
                    });
            })
        },
        overDue (date) {
            const now = new Date()
            const testDate = new Date(date)
            if(now.getTime() > testDate.getTime()) return true
            return false
        },
        updateShipment (val) {
            let tab = window.open('/v1/shipments/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.loadReport()
    },
    mounted() {
        this.handleResize()
        this.$emit('mounted')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
</style>